
.mobile-header {
    display: none !important;
    position: fixed;
    background-color: #2D38A8;
    border-bottom: 1px solid lightgray;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    width: 100%;
    height: 70px;
    z-index: 1;
    display: flex;
    justify-content: center;

    .icon-actions {
        margin: 27px;
    }

    .mobile-logo {
       margin: 24px 14px;

        img {
            width: 40px;
            height: 100%;
            border-radius: 5px;
        }
    }

    .mobile-title {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        height: 100%;

        h1 {
            color:white;
            text-align: center;
            margin: 0;
        }
    }
}


@media only screen and (max-width: 991px) {
  .mobile-header{
      display: flex !important;
  }
}
