
.sider-container {
    background-color: #000C17;
}

.admin-container { 
    
    .admin-main-header-container { 
        background: white!important;
        display: flex !important;
        justify-content: space-between !important;
        // text-align: right;
            cursor: pointer;
            .title{
                border: 2px solid #0F5E44;
                color:#0F5E44;
                font-weight: 500;
                margin: 0;
                padding:10px;
                border-radius: 8px;
            }

        .search {
            border-radius: 20px;
            max-width: 350px;
        }

    }

   
    .ant-menu-item {
        color:white !important;
        span a {
            color:white !important;
        }
    }

    .ant-menu-item-selected{
        background-color: white !important;
        color:gray !important;
        span {
            color:gray !important;
            a {
                 color:gray !important;
            }
        }
    }
}





.logo {
    // height: 32px;
    // background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img{
        height: 80px;
        width:80px;
        border-radius: 20px;
        border:1px solid white;
        margin:20px;
    }

    h4 {
        color:white;
        font-size: 18px;
    }
  }


.ant-table-content{
    overflow: auto;
}


