.primary-btn {
    background-color: #708791 !important;
    border-color: #708791 !important;
    height: 40px;
    border-radius: 6px;
    color:white;
    font-weight: bold;
    font-size: 12px;
    margin: 4px;
}

.primary-btn-block {
    background-color: #708791 !important;
    border-color: #708791 !important;
    height: 40px;
    border-radius: 6px;
    color:white;
    font-weight: bold;
    font-size: 12px;
    margin: 4px;
    width: 100%;
}

.primary-btn-outline {
    border: 2px solid #708791 !important;
    background-color: transparent !important;
    height: 40px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 12px;
    color:#708791;
    margin: 4px;
}

.ant-btn:hover, .ant-btn:focus {
    color: lightgray;
}

.primary-input{
    height: 45px;
    border-radius: 5px;
    border-color: #708791;
    margin:0;
}

.primary-input-select{
    .ant-select-selector {
        display: flex !important;
        align-items: center;
        height: 45px !important;
        border-radius: 5px !important;
        border-color: #708791 !important;
        margin:0 !important;
    }
}



//Grid
.row {
    display: flex;
    flex-grow:wrap;
}

.row-space-between {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}

.row-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
}

// Padding
.p1 { padding:4px }
.p2 { padding:8px }
.p3 { padding:12px }
.p4 { padding:16px }
.p5 { padding:20px }
.pt1 { padding-top:4px }
.pt2 { padding-top:8px }
.pt3 { padding-top:12px }
.pt4 { padding-top:16px }
.pt5 { padding-top:20px }
.pb1 { padding-bottom:4px }
.pb2 { padding-bottom:8px }
.pb3 { padding-bottom:12px }
.pb4 { padding-bottom:16px }
.pb5 { padding-bottom:20px }
.pl1 { padding-left:4px }
.pl2 { padding-left:8px }
.pl3 { padding-left:12px }
.pl4 { padding-left:16px }
.pl5 { padding-left:20px }
.pr1 { padding-right:4px }
.pr2 { padding-right:8px }
.pr3 { padding-right:12px }
.pr4 { padding-right:16px }
.pr5 { padding-right:20px }

// Margin
.m1 { margin:4px }
.m2 { margin:8px }
.m3 { margin:12px }
.m4 { margin:16px }
.m5 { margin:20px }
.mt1 { margin-top:4px }
.mt2 { margin-top:8px }
.mt3 { margin-top:12px }
.mt4 { margin-top:16px }
.mt5 { margin-top:20px }
.mb1 { margin-bottom:4px }
.mb2 { margin-bottom:8px }
.mb3 { margin-bottom:12px }
.mb4 { margin-bottom:16px }
.mb5 { margin-bottom:20px }
.ml1 { margin-left:4px }
.ml2 { margin-left:8px }
.ml3 { margin-left:12px }
.ml4 { margin-left:16px }
.ml5 { margin-left:20px }
.mr1 { margin-right:4px }
.mr2 { margin-right:8px }
.mr3 { margin-right:12px }
.mr4 { margin-right:16px }
.mr5 { margin-right:20px }

